import { forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode, useState } from "react";
import { mergeClasses } from "@/utils/common";
import { Text } from "@/components/common";
import { Label } from "@/components/form/label";

type DropdownOption = [label: string, value: string];

type DropdownInputProps = Omit<HTMLAttributes<HTMLDivElement>, keyof InputHTMLAttributes<HTMLSelectElement>> &
  InputHTMLAttributes<HTMLSelectElement> & {
    label?: string;
    error?: boolean;
    help?: ReactNode;
    options: DropdownOption[];
    disableEmptyOption?: boolean;
  };

export const DropdownInput = forwardRef<HTMLDivElement, DropdownInputProps>(
  (
    {
      label,
      error,
      help,
      className,
      disabled,
      readOnly,
      options,
      value,
      disableEmptyOption,
      onFocus,
      onBlur,
      onChange,
      ...rest
    }: DropdownInputProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const _className = mergeClasses("w-full", className);

    const containerClassName = mergeClasses(
      "relative",
      "px-6",
      "border-2",
      "rounded-full",
      !focused && "border-gray-300",
      focused && "border-black",
      error && "!border-red-500",
      (disabled || readOnly) && "!border-gray-100",
      onChange && !value && "text-gray-400"
    );

    const labelClassName = mergeClasses("absolute", "-top-2.5", "left-4", "px-2");

    const inputClassName = mergeClasses(
      "w-full",
      "py-3",
      "text-[14px]",
      "bg-transparent",
      "focus:outline-none",
      "focus:ring-0"
    );

    const helpClassName = mergeClasses("px-6", "mt-2", error && "text-red-500");

    return (
      <div ref={ref} className={_className}>
        <div className={containerClassName}>
          {label && (
            <Label className={labelClassName} disabled={disabled || readOnly}>
              {label}
            </Label>
          )}

          <select
            className={inputClassName}
            value={value}
            onFocus={(e) => {
              setFocused(true);
              onFocus?.(e);
            }}
            onBlur={(e) => {
              setFocused(false);
              onBlur?.(e);
            }}
            onChange={(e) => {
              onChange?.(e);
            }}
            disabled={disabled}
            {...rest}>
            {!disableEmptyOption && <option value="">Choose an option...</option>}
            {options.map((option) => (
              <option key={option[1]} value={option[1]}>
                {option[0]}
              </option>
            ))}
          </select>
        </div>

        {help && (
          <Text className={helpClassName} size="sm">
            {help}
          </Text>
        )}
      </div>
    );
  }
);
