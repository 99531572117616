import { forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode, useState } from "react";
import { mergeClasses } from "@/utils/common";
import { Text } from "@/components/common";
import { Label } from "@/components/form/label";

type RadioOption = [label: string, value: string];

type RadioInputProps = Omit<HTMLAttributes<HTMLDivElement>, keyof InputHTMLAttributes<HTMLInputElement>> &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    error?: boolean;
    help?: ReactNode;
    options: RadioOption[];
    inline?: boolean;
    align?: "top" | "bottom";
  };

export const RadioInput = forwardRef<HTMLDivElement, RadioInputProps>(
  (
    {
      label,
      className,
      error,
      help,
      disabled,
      readOnly,
      name,
      options,
      value,
      inline,
      align,
      onChange
    }: RadioInputProps,
    ref
  ) => {
    const [_name] = useState(name || Math.random().toString());

    const _className = mergeClasses("relative", "w-full", "px-6", className);

    const optionsContainerClassName = mergeClasses("flex", inline && "gap-2", !inline && "flex-col");

    const inputLabelClassName = mergeClasses(
      "inline-flex",
      "gap-2",
      "py-1.5",
      "text-[14px]",
      "bg-transparent",
      "focus:outline-none",
      "focus:ring-0",
      !align && "items-center",
      align === "top" && "items-start",
      align === "bottom" && "items-end"
    );

    const inputClassName = mergeClasses("w-4", "h-4", "my-0.5");

    const helpClassName = mergeClasses(error && "text-red-500");

    return (
      <div ref={ref} className={_className}>
        {label && <Label disabled={disabled || readOnly}>{label}</Label>}

        <div className={optionsContainerClassName}>
          {options.map((option) => (
            <label key={option[1]} className={inputLabelClassName}>
              <input
                type="radio"
                name={_name}
                value={option[1]}
                className={inputClassName}
                checked={value === option[1]}
                disabled={disabled}
                onChange={onChange}
              />

              <Text className="flex-1">{option[0]}</Text>
            </label>
          ))}
        </div>

        {help && (
          <Text className={helpClassName} size="sm">
            {help}
          </Text>
        )}
      </div>
    );
  }
);
