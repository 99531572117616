import { forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@/utils/common";
import { Text } from "@/components/common";
import { Label } from "@/components/form/label";

type TextAreaInputProps = Omit<HTMLAttributes<HTMLDivElement>, keyof InputHTMLAttributes<HTMLTextAreaElement>> &
  InputHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
    error?: boolean;
    help?: ReactNode;
    leading?: ReactNode;
    trailing?: ReactNode;
    maxHeight?: number;
  };

export const TextAreaInput = forwardRef<HTMLDivElement, TextAreaInputProps>(
  (
    {
      label,
      className,
      error,
      help,
      onFocus,
      onBlur,
      disabled,
      readOnly,
      leading,
      trailing,
      maxHeight = 150,
      ...rest
    }: TextAreaInputProps,
    ref
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [focused, setFocused] = useState(false);

    const _className = mergeClasses("w-full", className);

    const containerClassName = mergeClasses(
      "relative",
      "border-2",
      "rounded-3xl",
      !focused && "border-gray-300",
      focused && "border-black",
      error && "!border-red-500",
      (disabled || readOnly) && "!border-gray-100",
      !leading && "ps-6",
      !trailing && "pe-6",
      label && "mt-1"
    );

    const labelClassName = mergeClasses("absolute", "-top-2.5", "left-4", "px-2");

    const inputClassName = mergeClasses(
      "w-full",
      "py-3",
      "text-[14px]",
      "rounded-xl",
      "focus:outline-none",
      "focus:ring-0",
      "resize-none"
    );

    const helpClassName = mergeClasses("px-6", "mt-2", error && "text-red-500");

    useEffect(() => {
      if (textareaRef.current) {
        const textarea = textareaRef.current;
        textarea.style.height = "0px";

        const height = Math.min(maxHeight, textarea.scrollHeight);
        textarea.style.height = `${height}px`;
      }
    }, [rest.value]);

    return (
      <div ref={ref} className={_className}>
        <div className={containerClassName}>
          {label && (
            <Label className={labelClassName} disabled={disabled || readOnly}>
              {label}
            </Label>
          )}

          <div className="flex items-end gap-2">
            {leading}

            <textarea
              ref={textareaRef}
              className={inputClassName}
              rows={1}
              onFocus={(e) => {
                setFocused(true);
                onFocus?.(e);
              }}
              onBlur={(e) => {
                setFocused(false);
                onBlur?.(e);
              }}
              disabled={disabled}
              readOnly={readOnly}
              {...rest}
            />

            {trailing}
          </div>
        </div>

        {help && (
          <Text className={helpClassName} size="sm">
            {help}
          </Text>
        )}
      </div>
    );
  }
);
