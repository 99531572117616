import { ChangeEvent, forwardRef, HTMLAttributes, ReactNode, useRef } from "react";
import { mergeClasses } from "@/utils/common";
import { Picture, PictureTile, Text } from "@/components/common";
import { Label } from "@/components/form/label";
import plus from "@/assets/plus.svg";
import Image from "next/image";

interface PictureTileInputProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  label?: string;
  value: Picture[];
  error?: boolean;
  help?: ReactNode;
  disabled?: boolean;
  onChange: (e: { target: { value: Picture[] } }) => void;
}

export const PictureTileInput = forwardRef<HTMLDivElement, PictureTileInputProps>(
  ({ label, className, error, help, value, disabled, onChange }: PictureTileInputProps, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const _className = mergeClasses("relative", "w-full", "px-6", className);

    const gridClass = mergeClasses("grid", "grid-cols-3", "items-center", "gap-4");

    const helpClassName = mergeClasses("mt-1", error && "text-red-500");

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const newFiles = Array.from(e.target.files).map((file) => {
          return { file: file, ext: file.name.split(".").pop() || "jpeg" };
        });

        const pictures = [...newFiles, ...value];
        onChange({ target: { value: pictures } });
      }
    };

    const handleRemovePicture = (picture: Picture) => {
      const files = value.filter((pic) => pic !== picture);
      onChange({ target: { value: files } });
    };

    return (
      <div ref={ref} className={_className}>
        {label && (
          <Label className="mb-2" disabled={disabled}>
            {label}
          </Label>
        )}

        <input
          ref={inputRef}
          type="file"
          accept="image/jpeg,image/png"
          className="hidden"
          onChange={handleFileSelected}
          multiple
          hidden
        />

        <div className={gridClass}>
          {value.map((picture, idx) => (
            <PictureTile key={idx} src={picture} className="aspect-square" onRemove={handleRemovePicture} />
          ))}

          <button
            type="button"
            className="flex flex-col justify-center items-center gap-1 aspect-square border-2 border-dashed rounded-lg hover:bg-gray-100"
            onClick={() => inputRef.current?.click()}>
            <Image src={plus} alt="plus" />
            <Text size="sm">Add</Text>
          </button>
        </div>

        {help && (
          <Text className={helpClassName} size="sm">
            {help}
          </Text>
        )}
      </div>
    );
  }
);
