import { HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
}

export const Label = ({ className, children, disabled, ...rest }: LabelProps) => {
  const _className = mergeClasses(
    "block",
    "bg-white",
    "text-gray-500",
    "text-[12px]",
    "font-[500]",
    disabled && "!text-gray-300",
    className
  );

  return (
    <label className={_className} {...rest}>
      {children}
    </label>
  );
};
