import { forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode, useState } from "react";
import { mergeClasses } from "@/utils/common";
import { Text } from "@/components/common";
import { Label } from "@/components/form/label";

type TextInputProps = Omit<HTMLAttributes<HTMLDivElement>, keyof InputHTMLAttributes<HTMLInputElement>> &
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    error?: boolean;
    help?: ReactNode;
    leading?: ReactNode;
    trailing?: ReactNode;
    containerClassName?: string;
  };

export const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      label,
      className,
      error,
      help,
      onFocus,
      onBlur,
      leading,
      trailing,
      disabled,
      readOnly,
      containerClassName,
      ...rest
    }: TextInputProps,
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const _className = mergeClasses("w-full", className);

    const _containerClassName = mergeClasses(
      "relative",
      "px-6",
      "border-2",
      "rounded-full",
      !focused && "border-gray-300",
      focused && "border-black",
      error && "!border-red-500",
      (disabled || readOnly) && "!border-gray-100",
      label && "mt-2",
      containerClassName
    );

    const labelClassName = mergeClasses("absolute", "-top-2.5", "left-4", "px-2");

    const inputClassName = mergeClasses(
      "w-full",
      "py-3",
      "text-[14px]",
      "focus:outline-none",
      "focus:ring-0",
      (disabled || readOnly) && "!bg-transparent"
    );

    const helpClassName = mergeClasses("px-6", "mt-1", error && "text-red-500");

    return (
      <div ref={ref} className={_className}>
        <div className={_containerClassName}>
          {label && (
            <Label className={labelClassName} disabled={disabled || readOnly}>
              {label}
            </Label>
          )}

          <div className="flex items-center gap-2">
            {leading}

            <input
              type="text"
              className={inputClassName}
              onFocus={(e) => {
                setFocused(true);
                onFocus?.(e);
              }}
              onBlur={(e) => {
                setFocused(false);
                onBlur?.(e);
              }}
              disabled={disabled}
              readOnly={readOnly}
              {...rest}
            />

            {trailing}
          </div>
        </div>

        {help && (
          <Text className={helpClassName} size="sm">
            {help}
          </Text>
        )}
      </div>
    );
  }
);
