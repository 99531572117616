import { ReactNode, RefObject } from "react";
import { Button, PopupMenu, Text } from "@/components/common";
import { APP_SLOGAN } from "@/config/common";
import menuImg from "@/assets/menu.svg";
import Image from "next/image";
import Popup from "reactjs-popup";
import Link from "next/link";
import logoImg from "@/assets/logo.svg";
import logoWhiteImg from "@/assets/logo-white.svg";
import leftArrowImg from "@/assets/left-arrow.svg";
import hashkodeWhiteImg from "@/assets/hashkode-white.svg";
import { useRouter } from "next/router";

interface PublicLayoutProps {
  back?: string | boolean;
  hideMenu?: boolean;
  hideFooter?: boolean;
  contentRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

export const PublicLayout = ({ back, hideMenu, hideFooter, contentRef, children }: PublicLayoutProps) => {
  const router = useRouter();

  const handleBack = () => {
    router.back();
  };

  return (
    <div className="h-full flex flex-col overflow-hidden">
      <nav className="h-[60px]">
        <div className="container h-full flex items-center gap-4 px-8 py-2 lg:py-4">
          {typeof back === "string" ? (
            <Link href={back}>
              <Image src={leftArrowImg} alt="back" />
            </Link>
          ) : typeof back === "boolean" ? (
            <button type="button" onClick={handleBack}>
              <Image src={leftArrowImg} alt="back" />
            </button>
          ) : null}

          <Link href="/">
            <Image src={logoImg} height={24} alt="App logo" />
          </Link>

          <span className="flex-1" />

          {!hideMenu && (
            <>
              <Popup
                position="bottom right"
                offsetY={-10}
                trigger={
                  <Button variant="link" className="!px-3 lg:hidden">
                    <Image src={menuImg} alt="Menu icon" />
                  </Button>
                }>
                <PopupMenu className="p-3">
                  <Link href="/login">
                    <Button className="!px-3" size="sm" flex>
                      Get Started
                    </Button>
                  </Link>
                </PopupMenu>
              </Popup>

              <Link href="/login" className="hidden lg:block">
                <Button className="!px-3" size="sm" flex>
                  Get Started
                </Button>
              </Link>
            </>
          )}
        </div>
      </nav>

      <div ref={contentRef} className="flex-1 overflow-auto">
        {children}

        {!hideFooter && (
          <footer className="bg-black text-white">
            <div className="container px-8 py-4 flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-4">
              <div className="flex flex-col items-center lg:items-stretch">
                <Image src={logoWhiteImg} height={28} alt="Logo white" />
                <Text size="xs">{APP_SLOGAN}</Text>
              </div>

              <div className="flex items-center gap-2 md:gap-8">
                <Link href="/terms-of-service" className="hover:underline">
                  <Text size="sm" align="center">
                    Terms of Service
                  </Text>
                </Link>

                <div className="w-[1px] h-4 bg-gray-400" />

                <Link href="/privacy-policy" className="hover:underline">
                  <Text size="sm" align="center">
                    Privacy Policy
                  </Text>
                </Link>

                <div className="w-[1px] h-4 bg-gray-400" />

                <Link href="mailto:info@getonetrust.com" className="hover:underline">
                  <Text size="sm" align="center">
                    Contact Us
                  </Text>
                </Link>
              </div>

              <div className="flex items-center gap-2">
                <Text>Powered by</Text>
                <a href="https://hashkode.dev" target="_blank">
                  <Image src={hashkodeWhiteImg} width={128} alt="Hashkode" />
                </a>
              </div>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};
